@mixin Sprite {
  background-size: 103px 300px;
  background-repeat: no-repeat;
  background-image: url("./assets/sheet.png");
}
#Facebook {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  background: #ccc;
  position: relative;
  color: rgb(29, 33, 41);
  height: fit-content;
  .header {
    background: #fff;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    .logo {
      height: 25px;
      width: auto;
    }
    .toolbar {
      background: #fff;

      display: flex;
      button {
        img {
          height: 20px;
          width: auto;
        }
        height: 35px;
        width: 35px;
        background: #ccc;
        border-radius: 50%;
        border: 0px solid;
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .user-post {
    background: #fff;
    border-bottom: 1px solid #eee;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      img {
        height: 20px;
        width: auto;
      }
      height: 35px;
      width: 35px;
      background: #ccc;
      border-radius: 50%;
      border: 0px solid;
      margin-left: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .profile {
      border-radius: 50%;
      height: 36px;
      width: 36px;
      float: left;
      background-color: #ccc;
      background-size: cover;
      background-position: 0px 3px;
      background-repeat: no-repeat;
      object-fit: cover;
    }
    input {
      height: 30px;
      display: block;
      padding: 0.5rem;
      border: 0px;
      appearance: none;
      font-size: 1rem;
      width: calc(100% - 50px);
    }
  }
  .verified {
    height: 16px;
    width: 16px;
    display: inline-block;
    img {
      margin-bottom: -3px;
    }
  }
  .actionbar {
    margin-top: 8px;
    background: #fff;
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 6px;
    font-size: 14px;
    font-weight: bold;
    div {
      padding: 0.5rem;
      text-align: center;
    }
  }
  .test-end {
    margin: 0 auto;
    appearance: none;
    color: #707070;
    font-size: 16px;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    width: 80%;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    font-family: "Ropa Sans", sans-serif;
    min-height: 48px;
    align-items: center;
  }
  .videochat-preview {
    background: linear-gradient(180deg, #eee, white);
    margin-bottom: 0.5rem;
    position: relative;

    display: flex;
    padding: 0.5rem;
    .create {
      border-radius: 25px;
      text-align: left;
      padding: 5px 10px;
      padding-left: 50px;
      appearance: none;
      background: transparent;
      border: 1px solid #4267b2;
      color: #4267b2;
    }
    .user-list {
      display: flex;
      .user {
        background-color: #ccc;
        background-size: cover;
        margin-left: 0.5rem;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        position: relative;
        &:after {
          height: 12px;
          width: 12px;
          content: "";
          background: rgb(66, 183, 42);
          bottom: 0;
          right: 0;
          border: 1px solid #fff;
          border-radius: 50%;
          display: block;
          position: absolute;
        }
      }
    }
  }
  .navigation {
    z-index: 9999;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    display: grid;
    height: 80px;
    flex-direction: column;
    align-items: stretch;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    width: 100%;
    div {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 0.5rem;
      border-top: 2px solid #ffffff;

      img {
        max-height: 25px;
        max-width: 25px;
        height: 25px;
        width: auto;
      }
      &.active {
        border-top: 2px solid #4267b2;
      }
    }
  }
  .post-header {
    padding: 1rem;
    .profile {
      height: 40px;
      width: 40px;
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      float: left;
      margin-right: 1rem;
      overflow: hidden;
      img {
        object-fit: fill;
        height: 40px;
      }
    }
    .info {
      .name {
        font-weight: 600;
      }
      .time {
        font-size: 12px;
        color: rgb(144, 148, 156);
        img {
          height: 12px;
          width: auto;
          margin-bottom: -2px;
        }
      }
    }
  }
  .stream {
    padding-bottom: 80px;
    .post {
      min-height: 50vh;
      background: #fff;
      margin-bottom: 1rem;
      .post-text {
        font-size: 14px;
        padding: 0 1rem;
        padding-bottom: 1rem;
      }
      .post-template {
        &.image {
          img {
            width: 100%;
            height: auto;
          }
        }
        &.video {
          video {
            width: 100%;
            height: auto;
          }
        }
        &.link {
          .link-preview {
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            .preview-image {
              background-size: cover;
              height: 200px;
            }
            .text {
              padding: 1rem;
              background: #eee;
              .url {
                color: #464950;
                font-size: 12px;
                line-height: 16px;

                text-transform: uppercase;
              }
              .preview-text {
                font-size: 14px;
                line-height: 18px;
                font-weight: bold;
              }
            }
          }
        }
      }
      .post-footer {
        border-top: 1px solid #eee;
        font-size: 12px;
        color: #616770;
        display: flex;
        padding: 1rem;
        justify-content: space-between;
        .likes {
          .like {
            background-position: -34px -239px;
            background-size: 103px 300px;
            background-repeat: no-repeat;
            background-image: url("./assets/sheet.png");
            display: inline-block;
            margin-right: 0.3rem;
            margin-bottom: -3px;
            height: 16px;
            width: 16px;
          }
        }
        .right {
          div {
            margin-left: 0.5rem;
            float: left;
          }
          .me {
            .profile {
              height: 20px;
              width: 20px;
              background: #ccc;
              border-radius: 50%;
              margin-top: -1px;
              background-size: cover;
            }
            .dropdown {
              width: 16px;
              height: 16px;
              background-repeat: no-repeat;
              background-position: -68px -222px;
              background-size: 103px 300px;
              background-image: url("./assets/sheet.png");
              margin-top: -1px;
              margin-left: 0;
            }
          }
        }
      }
      .post-actions {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display: flex;
        justify-content: space-between;
        div {
          padding: 0.5rem;
          text-align: center;
          font-size: 13px;
          font-weight: 600;
          color: #606770;
          i {
            display: inline-block;
            @include Sprite;
          }
          &.like i {
            background-position: -42px -180px;
            height: 20px;
            width: 20px;
            margin-right: 0.2rem;
            margin-bottom: -3px;
          }
          &.share i {
            background-position: -62px -180px;
            height: 20px;
            width: 20px;
            margin-right: 0.2rem;
            margin-bottom: -3px;
          }
          &.comment i {
            background-position: -22px -180px;
            height: 20px;
            width: 20px;
            margin-right: 0.2rem;
            margin-bottom: -3px;
          }
        }
      }
    }
  }
  .post-content {
    position: relative;
    .focus-top {
      position: absolute;
      height: 2px;
      top: 80%;
      width: 100%;

      z-index: 99999999;
      pointer-events: none;

      opacity: 0.5;
    }

    .focus-bottom {
      pointer-events: none;
      position: absolute;
      height: 50%;
      top: 25%;
      width: 100%;
      z-index: 99999999;

      opacity: 0.5;
    }
  }
  .ins-button {
    background: url(./assets/instagram.jpg);
    background-size: cover;
    background-position: 50% 65%;
    color: white;
  }
}

@media (min-width: 1200px) {
  #Facebook {
    height: 675px;
    .navigation {
      position: relative;
    }
  }
}

.newReels {
  width: 30%;
  height: 180px;
  .profile {
    height: 65%;
    background-color: #ccc;
    background-size: cover;
    background-position: 50% 7px;
    background-repeat: no-repeat;
    border-radius: 7px 7px 0 0;
    position: relative;
    .plus {
      background-color: #4267b2;
      z-index: 99;
      height: 32px;
      width: 32px;
      position: absolute;
      left: 50%;
      bottom: -16px;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      img {
        height: 20px;
      }
    }
  }
  .text {
    text-align: center;
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    padding-top: 12px;
    background-color: #eee;
    border-radius: 0 0 7px 7px;
  }
}
