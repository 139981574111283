@import url("https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap");

#Start {
  font-family: "Ropa Sans", sans-serif;
  padding: 2rem;
  width: 100%;
  overflow: hidden;
  height: 100%;
  max-height: 100%;

  overflow-y: auto;
  background: #fff;
  font-size: 16px;
  color: #707070;
  margin: 0 auto;
  .Logo {
    width: 250px;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.7rem;
  }
  .prefetch {
    height: 1px;
    width: 1px;
    opacity: 0;
  }
  input,
  select,
  button {
    appearance: none;
    color: #707070;
    font-size: 16px;

    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    font-family: "Ropa Sans", sans-serif;
    min-height: 48px;
    align-items: center;
  }
  .slideshow {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    input {
      margin-right: 1rem;
      height: 1.3rem;
      width: 1.3rem;
      line-height: 1;
      padding: 0;
      min-height: auto;
      &:checked {
        background: #71401f;
      }
    }
  }

  select {
    background-image: url("../../images/arrow.svg");
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: right 1rem center;
  }
  .tag-wrapper {
    .tag-list {
      border-radius: 5px;
      border: 1px solid #ccc;
      margin-bottom: 0.5rem;
      min-height: 48px;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      .tag {
        background: #707070;
        padding: 0.2rem 0.5rem;
        display: inline-block;
        color: #fff;
        float: left;
        margin-right: 0.2rem;
        border-radius: 3px;
        margin-bottom: 0.2rem;
        padding-right: 1.5rem;
        background-image: url("../../images/close.svg");
        background-repeat: no-repeat;
        background-position: right 3px center;
        background-size: 14px;
      }
    }
    .tag-options {
      clear: both;
      .tag-placeholder {
        background: #707070;
        color: #fff;

        float: left;
        border: 1px solid #ccc;
        border-radius: 3px;
        margin-right: 0.2rem;
        padding: 0.2rem 0.5rem;
      }
      margin-bottom: 1rem;
    }
    clear: both;
    margin-bottom: 1.5rem;
  }
  .form-back-button {
    background-color: grey;
  }
  button {
    justify-content: Center;
    color: #fff;
    appearance: none;
    border: 0px;
    background-color: #71401f;
  }
  .sub {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
  .profile-upload {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  input[type="file"] {
    opacity: 0;
  }
}

.field-error {
  color: red;
  padding-left: 15px;
  margin-bottom: 5px;
}
