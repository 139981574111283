#login {
    font-family: "Ropa Sans", sans-serif;
    .Logo {
        width: 250px;
        height: auto;
        display: block;
        margin: 0 auto;
        margin-bottom: 0.7rem;
        margin-top: 150px;
    }
    display: flex;
    flex-direction: column;

    label {
        margin: 10px 50px;
    }
    input {
        margin: 5px 50px;
    }
    button {
        margin: 5px 50px;
    }

    input,
    select,
    button {
        color: #707070;
        font-size: 16px;
        padding: 0.5rem 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        display: flex;

        box-sizing: border-box;
        margin-bottom: 0.5rem;

        min-height: 48px;
        align-items: center;
        justify-content: center;
    }
    button {
        background-color: grey;
        color: white;
    }
}
