@mixin footer-icons {
  background-size: contain;
  background-position: center;
  height: 30px;
  width: 30px;
}
body {
  overflow: hidden;
  overflow-y: scroll;
}

.stream-bar-container::-webkit-scrollbar {
  display: none;
}
.stream-bar-container {
  overflow: -moz-scrollbars-none;
}

button:disabled {
  color: grey;
}

#instagram {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 12px;
  line-height: 14px;
  width: 100vw;
  height: fit-content;
  position: relative;
  padding-bottom: 100px;
  &.reel {
    overflow: hidden;
  }
  .test-end {
    margin: 0 auto;
    appearance: none;
    color: #707070;
    font-size: 16px;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    width: 80%;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    font-family: "Ropa Sans", sans-serif;
    min-height: 48px;
    align-items: center;
  }
  .header {
    z-index: 999;
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    height: 54px;
    width: 100%;

    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    padding: 0 12px;
    .header-icon-bar {
      display: flex;
      flex-direction: row;
    }
    .header-icon {
      height: 30px;
      width: 30px;

      &.icon-posting {
        background-image: url(./assets/ins-posting.svg);
        background-size: contain;
        background-position: center;
        align-self: center;
        margin-right: 16px;
      }

      &.tv {
        background-image: url(./assets/ins-favorite.svg);
        background-size: contain;
        background-position: center;
        align-self: center;
        margin-right: 16px;
      }
      &.send {
        background-image: url(./assets/ins-send.svg);
        background-size: contain;
        background-position: center;
        align-self: center;
      }
    }
    img {
      align-self: center;
      margin-right: -50px;
      margin-top: 5px;
    }
  }
  .ins-carousel {
    .single-slicker {
      transform: none;
      padding: 0;
    }
    .slick-dots {
      li {
        font-size: 2px;
        width: 10px;
        height: 10px;
        button {
          width: 10px;
          height: 10px;
          &::before {
            content: "";
            background-color: grey;
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .footer {
    position: fixed;
    display: flex;
    bottom: 0;
    left: 0;
    height: 80px;
    width: 100%;
    border-top: 1px solid #ccc;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    padding: 0 20px;
    align-items: center;
    z-index: 999;

    .icon-home {
      background-image: url(./assets/ins-home.svg);
      @include footer-icons();
    }
    .icon-search {
      background-image: url(./assets/ins-search.svg);
      @include footer-icons();
    }
    .icon-posting {
      background-image: url(./assets/ins-posting.svg);
      @include footer-icons();
    }
    .icon-reels {
      background-image: url(./assets/ins-reels.svg);
      @include footer-icons();
    }
    .icon-favorite {
      background-image: url(./assets/ins-bag.svg);
      @include footer-icons();
    }
    .icon-userinfo {
      background-image: url(./assets/ins-user.svg);
      @include footer-icons();
    }
    &.black {
      background: black;
      border: none;
      .icon-home {
        background-image: url(./assets/ins-home-leer.svg);
        @include footer-icons();
      }
      .icon-search {
        background-image: url(./assets/ins-search-white.svg);
        @include footer-icons();
      }
      .icon-posting {
        background-image: url(./assets/ins-posting.svg);
        @include footer-icons();
      }
      .icon-reels {
        background-image: url(./assets/ins-reels-white.svg);
        @include footer-icons();
      }
      .icon-favorite {
        background-image: url(./assets/ins-bag-white.svg);
        @include footer-icons();
      }
      .icon-userinfo {
        background-image: url(./assets/ins-user-white.svg);
        @include footer-icons();
        border-radius: 50%;
        padding: 12px;
        border: 2px solid #fff;
      }
      @media (min-width: 1200px) {
        position: absolute;
        width: 100%;
      }
    }
  }
  .stream-bar-container {
    width: 100vw;
    height: 100px;
    overflow-x: scroll;

    position: relative;
    margin-top: 60px;
  }
  .stream-bar {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    width: fit-content;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;

    .user-stream {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 20px;
      span {
        margin-top: 5px;
      }
      .plus-icon {
        position: absolute;
        height: 20px;
        width: 20px;
        right: -6px;
        bottom: -3px;
        background-image: url(./assets//ins-add-stream.svg);
        background-color: white;
        background-size: 95%;
        border-radius: 50%;
      }
      .user-stream-img {
        height: 56px;
        width: 56px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        position: relative;
      }
    }
  }

  .post-container {
    margin: 20px 0;

    overflow: hidden;
    .post-user {
      padding: 0 15px;
      width: 100%;
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      margin-bottom: 10px;
      > div {
        flex-shrink: 0;
      }
      .post-user-img {
        height: 35px;
        width: 35px;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        flex-shrink: 0;
        overflow: hidden;
        img {
          object-fit: cover;
          height: 35px;
        }
      }
      .post-user-name {
        font-weight: bold;
        margin-left: 10px;
        align-self: center;
        width: 100%;
        .sponsored {
          font-weight: 400;
          display: block;
          color: #666;
        }
      }
      .more {
        align-self: center;
        margin-left: 50vw;
        background-repeat: no-repeat;
        height: 15px;
        width: 15px;
        background-image: url(./assets/ins-more.svg);
        background-position: center;
        background-size: contain;
      }
    }
    .icon-bar {
      width: 100%;
      padding: 5px 5px;
      position: relative;
      .icon-bar-left {
        display: flex;
        width: fit-content;
        .icon-like {
          @include footer-icons();
          background-image: url(./assets/ins-favorite.svg);
          margin-right: 10px;
        }
        .icon-comment {
          @include footer-icons();
          margin-right: 10px;
          background-image: url(./assets/ins-comment.svg);
        }
        .icon-send {
          @include footer-icons();
          background-image: url(./assets/ins-send.svg);
        }
      }
      .icon-collection {
        @include footer-icons();
        background-image: url(./assets/ins-collection.svg);
        position: absolute;
        right: 10px;
        top: 5px;
      }
    }
    .post-info {
      padding: 5px 10px;
      max-width: 90%;
      .view-like-number {
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
    .add-comment {
      padding: 8px 0;
      display: flex;
      align-items: center;
      .add-comment-img {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      span {
        margin-left: 10px;
        color: #666;
      }
    }
    .time {
      color: #666;
    }
    .image {
      img {
        width: 100vw;
      }
    }
  }
  .post-content {
    position: relative;
    .focus-top {
      position: absolute;
      height: 2px;
      top: 35%;
      width: 100%;
      pointer-events: none;

      border-bottom: 2px dotted red;
    }

    .focus-bottom {
      pointer-events: none;

      position: absolute;
      height: 2px;
      background-color: red;
      top: 75%;
      width: 100%;

      opacity: 0.5;
    }

    .post-text {
      padding: 1rem;
      font-size: 14px;
    }
  }
  .verified {
    height: 16px;
    width: 16px;
    display: inline;
    img {
      height: 16px;
      width: 16px;
      margin-bottom: -3px;
    }
  }
}

@media (min-width: 1200px) {
  #instagram {
    height: 675px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    overflow: scroll;
    .header {
      position: absolute;
    }
    .stream-bar-container {
      width: 100%;
    }
    .post-container .image img {
      width: 100%;
    }
    .footer {
      position: relative;
    }
  }
}

.insta-reel-slider {
  background: #000;
}
