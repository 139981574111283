.debug-container {
  position: fixed;
  z-index: 99999;
  height: 32px;
  width: 95%;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  color: white;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  font-size: 16px;
  font-family: "Ropa Sans", sans-serif;
}
.view-line {
  width: 100%;
  border-top: dotted red 2px;
  text-align: end;
  color: red;
  &.leave {
    color: green;
    border-bottom: dotted green 2px;
  }
}

.focus-line {
  width: 100%;
  border-top: dotted red 2px;
  position: absolute;
  top: 25%;
  color: red;
  text-align: end;
  height: 50%;
  &.bottom {
    top: 75%;
    color: green;
    border-bottom: dotted green 2px;
  }
}
