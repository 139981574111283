@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,500&display=swap");

#tik-tok {
  height: auto;
  font-family: "Montserrat", sans-serif;
  video {
    z-index: 1000;
  }

  .video-wrapper {
    height: 46vh;
    width: 100vw;
    overflow: visible;
    position: relative;
    display: flex;
    justify-items: center;
    align-content: center;
    z-index: 1;
  }

  .tik-tok-video {
    margin: 0 auto;
    height: 100vh;
    min-width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-container {
    width: 100%;
    height: 100vh;
  }

  .tik-tok-title {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 1rem;
    z-index: 2;
    color: white;
    b {
      position: relative;
    }
    b:after {
      content: "";
      width: 2rem;
      height: 3px;
      background: #fff;
      position: absolute;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .search {
    position: absolute;
    top: 2rem;
    right: 1rem;
    z-index: 99;
    height: 1.5rem;
    width: auto;
  }

  .live {
    position: absolute;
    top: 2rem;
    left: 1rem;
    z-index: 99;
    height: 1.5rem;
    width: auto;
  }

  .tik-tok-sidebar {
    position: absolute;
    right: 0;
    width: 60px;
    bottom: 60px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    color: white;
    justify-items: center;
    align-items: center;
    font-size: 12px;
    .tik-tok-author {
      height: 45px;
      width: 45px;
      border-radius: 50%;

      background-size: contain;
      background-position: center;
      margin-bottom: 20px;
      position: relative;
      .add-author {
        background-color: red;
        padding: 0 4px 2px 4px;
        border-radius: 50%;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .tik-tok-like {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      .like-icon {
        height: 40px;
        width: 40px;
        background-size: contain;
        background-image: url(./assets/love.svg);
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 3px;
      }
    }
    .tik-tok-bookmark {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      .bookmark-icon {
        height: 35px;
        width: 40px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(./assets/bookmark.svg);
        background-position: center;
        margin-bottom: 3px;
      }
    }
    .tik-tok-comment {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      .comment-icon {
        height: 40px;
        width: 40px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(./assets/comment.svg);
        background-position: center;
        margin-bottom: 3px;
      }
    }
    .tik-tok-share {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      .share-icon {
        height: 35px;
        width: 35px;
        background-size: contain;
        background-image: url(./assets/share.svg);
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 3px;
      }
    }
    .tik-tok-musik {
      height: 45px;
      width: 45px;
      border-radius: 50%;
      background: linear-gradient(to bottom right, black, rgba(0, 0, 0, 0.4));
      display: flex;
      justify-content: center;
      align-items: center;
      .musik {
        height: 25px;
        width: 25px;
        border-radius: 50%;

        background-size: contain;
        background-position: center;
      }
    }
    @-webkit-keyframes rotate {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-moz-keyframes rotate {
      from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(359deg);
      }
    }
    @-o-keyframes rotate {
      from {
        -o-transform: rotate(0deg);
      }
      to {
        -o-transform: rotate(359deg);
      }
    }
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
  .focus-top {
    position: relative;
    height: 20px;
    margin-top: 100px;
    width: 100%;
    opacity: 0;
    z-index: 100;
  }

  .focus-bottom {
    pointer-events: none;

    position: relative;
    height: 50%;
    margin-top: 200px;
    width: 100%;

    opacity: 0;
  }

  .tik-tok-footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 6vh;
    background-color: #000;
    z-index: 2;
    justify-content: space-around;
    font-size: 10px;
    align-items: center;
    display: flex;

    .home {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      margin-top: 5px;
      .home-icon {
        height: 25px;
        width: 25px;
        background-size: contain;
        background-image: url(./assets/home.svg);
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 3px;
      }
    }
    .entdecken {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      margin-top: 5px;
      .entdecken-icon {
        height: 25px;
        width: 25px;
        background-size: contain;
        background-image: url(./assets/search.svg);
        background-position: center;
        margin-bottom: 3px;
      }
    }
    .friends {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      margin-top: 5px;
      .friends-icon {
        height: 23px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(./assets/friends.svg);
        background-position: center;
        margin-bottom: 3px;
      }
    }
    .mail {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      margin-top: 5px;
      .mail-icon {
        height: 21px;
        width: 25px;
        background-size: contain;
        background-image: url(./assets/mail.svg);
        background-position: center;
        margin-bottom: 3px;
        background-repeat: no-repeat;
      }
    }
    .add-blue {
      font-weight: bold;
      padding: 2px 15px 3px 14px;
      border-radius: 5px;
      background-color: deepskyblue;
      color: white;
      font-size: 22px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transform: translateX(-3px);
    }
    .add-red {
      font-weight: bold;
      padding: 2px 15px 3px 14px;
      border-radius: 5px;
      background-color: indianred;
      color: white;
      font-size: 22px;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(3px);
      z-index: 1;
    }
    .add {
      font-weight: bold;
      padding: 2px 15px 3px 14px;
      border-radius: 5px;
      background-color: white;
      color: black;
      font-size: 22px;
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0px;
    }
    .add-box {
      font-weight: bold;
      padding: 2px 15px 3px 14px;
      border-radius: 5px;
      color: black;
      font-size: 22px;
      z-index: 5;
      background-image: url(./assets/add.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 30px;
      width: 45px;
    }

    .user {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      margin-top: 5px;
      .user-icon {
        height: 25px;
        width: 25px;
        background-image: url(./assets/user.svg);
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 3px;
      }
    }
  }
  .styles_ticker__8iZE9 {
    padding: 0 !important;
  }

  .tiktok-leftbar {
    position: absolute;
    left: 15px;
    bottom: 60px;
    max-width: 70%;
    display: block;
    z-index: 10;
  }
  .tiktok-caption {
    padding: 5px 0;
    overflow: hidden;

    * {
      font-size: 16px;
      color: white !important;
    }
    p {
      padding: 0;
      margin: 0;
    }
  }
  .user-name {
    z-index: 2;
    color: white;
    font-weight: 600;
  }
  .sponsor {
    padding: 3px 5px;
    font-size: 8px;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.3);
    width: fit-content;
  }
  .vertifiziert {
    height: 12px;
    width: 12px;
    background-image: url(./assets/checked.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block;
  }
  .musik-ticker {
    display: flex;
    z-index: 2;
    align-items: center;
    color: white;
    .musik-icon {
      height: 20px;
      width: 20px;
      background-size: contain;
      background-image: url(./assets/music.svg);
      background-position: center;
      margin-right: 5px;
    }
    .styles_ticker__8iZE9 {
      background-color: transparent;
      width: 220px;
      max-width: 80%;
    }
  }
  .werb-button {
    z-index: 3;
    padding: 6px;
    text-align: center;

    width: 100%;
    color: white;
    border-radius: 5px;
  }
  .test-end {
    margin: 0 auto;
    appearance: none;
    color: #707070;
    font-size: 16px;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    width: 80%;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    margin-top: 40vh;
    font-family: "Ropa Sans", sans-serif;
    min-height: 48px;
    align-items: center;
  }
  .ins-button {
    background: url(../facebook/assets/instagram.jpg);
    background-size: cover;
    background-position: 50% 65%;
    color: white;
  }
}
@media (min-width: 1200px) {
  #tik-tok {
    overflow: hidden;
    height: 675px;
    .tik-tok-video {
      height: 675px;
    }
    .swiper-container {
      height: 675px;
    }
    .video-wrapper {
      height: 675px;
      width: 375px;
    }
    .tik-tok-footer {
      position: absolute;
      width: 100%;
    }
  }
}

.music-animation {
  position: absolute;
  bottom: 170px;
  right: -20px;
  height: 60px;
  width: 100px;
  z-index: 3;
  display: flex;
  animation: music_rotate linear 5s infinite;
  .music1 {
    height: 20px;
    width: 20px;
    margin-left: 5px;
    background-image: url(./assets/music1.svg);
    background-size: contain;
    background-position: center;
    animation: music_rotate1 linear 5s infinite;
    @keyframes music_rotate1 {
      0% {
        opacity: 1;
        transform: translateX(-15px);
      }

      5% {
        opacity: 0;
      }

      60% {
        opacity: 0;
        transform: scale(0.5);
      }

      70% {
        opacity: 1;
        transform: rotate(70deg) scale(0.7);
      }
      80% {
        opacity: 1;
        transform: rotate(50deg) translateX(-5px) scale(1);
      }

      90% {
        opacity: 1;
        transform: rotate(30deg) translateX(-10px);
      }

      100% {
        transform: translateX(-15px);
        opacity: 1;
      }
    }
  }
  .music2 {
    height: 20px;
    width: 20px;
    background-image: url(./assets/music2.svg);
    background-size: contain;
    background-position: center;
    margin-top: 30px;
    margin-left: -20px;
    animation: music_rotate2 linear 5s infinite;
    @keyframes music_rotate2 {
      0% {
        opacity: 1;
        transform: rotate(-10deg);
      }

      10% {
        opacity: 0;
        transform: rotate(0deg);
      }
      65% {
        opacity: 0;
        transform: scale(0.3);
      }
      70% {
        transform: rotate(70deg) scale(0.5);
      }

      80% {
        opacity: 1;
        transform: rotate(30deg) scale(0.7);
      }

      100% {
        opacity: 1;
        transform: rotate(-10deg) scale(1);
      }
    }
  }
  .music3 {
    height: 20px;
    width: 20px;
    background-image: url(./assets/music3.svg);
    background-size: contain;
    background-position: center;
    margin-top: 60px;
    margin-left: -10px;
    animation: music_rotate3 linear 5s infinite;
    @keyframes music_rotate3 {
      0% {
        opacity: 1;
        transform: rotate(0) scale(1);
      }

      15% {
        opacity: 1;
        transform: rotate(-55deg);
      }
      20% {
        opacity: 0;
      }
      85% {
        opacity: 0;
        transform: scale(0.3);
      }

      95% {
        opacity: 1;
        transform: scale(0.8);
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  @keyframes music_rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

body {
  height: 100%;
  position: relative;
}
#tiktok-end {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tiktok-slider {
  position: relative;
  background: black;
  .slick-dots {
    bottom: 20vh;
    button:before {
      color: white;
      font-size: 10px;
    }
  }
}

.tiktok-slider .slick-active button:before {
  color: white !important;
  opacity: 1 !important;
}

.tiktok-slide {
  height: 100vh;
  display: flex !important;
  align-items: center;
}

.tiktok-slide img {
  width: 80%;
  height: 60vh;
  object-fit: contain;
  margin: 0 auto;
}
