.igstory-container {
    min-height: 100vh;
    min-width: 100vw;
    position: fixed;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
    background: rgb(17, 17, 17);
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    * {
        filter: none !important;
    }
    .swiper-container {
        height: 100%;
        width: 100vw;
        position: absolute;
    }
    .swiper-wrapper {
        height: 100%;
    }
    img {
        height: 80vh;
        width: auto;
        transform: translateY(-10vh);
    }

    .video-js {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        .vjs-tech {
            height: 100vh;
            width: 100vw;
            position: absolute;
            left: 50%;
            top: 40%;
            transform: translate(-50%, -50%);
        }
        .vjs-control-bar {
            top: 0;
            background-color: transparent;
        }
        .vjs-play-control,
        .vjs-remaining-time,
        .vjs-picture-in-picture-control,
        .vjs-fullscreen-control,
        .vjs-big-play-button {
            display: none;
        }
        .vjs-play-progress {
            &:before {
                display: none;
            }
        }
        .vjs-volume-panel {
            position: absolute;
            transform: scale(1.5);
            top: 50px;
            right: 20px;
        }
    }
    .test-end {
        margin: 0 auto;
        appearance: none;
        color: #707070;
        font-size: 16px;
        padding: 0.5rem 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        width: 80%;
        box-sizing: border-box;
        margin-bottom: 0.5rem;
        font-family: "Ropa Sans", sans-serif;
        min-height: 48px;
        align-items: center;
        &#test-end-ins {
            background: url(../facebook/assets/instagram.jpg);
            background-size: cover;
            background-position: 50% 65%;
            color: white;
        }
        &#test-end-tiktok {
            background-color: rgb(17, 17, 17);
            color: white;
        }
    }
}

.igstory-card {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: rgb(17, 17, 17);
    overflow: hidden;
    .wrapper {
        position: absolute;
        background-color: transparent;
        min-width: 100vw;
        min-height: 100vh;
        top: 0;
        left: 0;
        z-index: 999;
    }
    .ig-author-bild {
        height: 40px;
        width: 40px;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        position: absolute;
        top: 50px;
        left: 20px;
        z-index: 5;
    }
    .ig-author {
        color: white;
        position: absolute;
        top: 60px;
        left: 80px;
        z-index: 5;
        font-weight: bold;
    }

    .igstory-footer {
        background-color: black;
        position: absolute;
        bottom: 100px;
        right: 0px;
        z-index: 4;
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .ig-message {
            color: white;
            padding: 10px 15px;
            border: solid white 1px;
            border-radius: 20px;
            z-index: 5;
            width: 80vw;
        }
        .ig-send-icon {
            height: 10vw;
            width: 10vw;
            background-image: url(./ins-send.svg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 5;
        }
    }

    .button-container {
        min-height: 100vh;
        background-color: rgb(17, 17, 17);
        min-width: 100vw;
        position: absolute;
    }
}

@media (min-width: 1200px) {
    .igstory-container {
        min-height: 675px;
        min-width: 375px;
        .swiper-container {
            width: 100%;
            img {
                height: auto;
            }
        }
        .wrapper {
            height: 100%;
            width: 100%;
        }
        .video-js {
            width: 100%;
            height: 100%;
            .vjs-tech {
                height: 100%;
                width: 100%;
            }
        }
    }
}
